import type { AppProps } from 'next/app';
import '@/styles/globals.scss';
import Head from 'next/head';
import Script from 'next/script';

type Props = AppProps;

const App: React.FC<Props> = ({ Component, pageProps }) => (
  <>
    <Head>
      <link rel='icon' href='https://cdn.lensa.com/img/icons/favicon-32.png' sizes='32x32' />
      <link rel='icon' href='https://cdn.lensa.com/img/icons/favicon-128.png' sizes='128x128' />
      <link rel='apple-touch-icon' href='https://cdn.lensa.com/img/icons/favicon-180.png' sizes='180x180' />
      <link rel='icon' href='https://cdn.lensa.com/img/icons/favicon-192.png' sizes='192x192' />
      <meta name='description' content='Lensa FastTalent' />
      <title>FastTalent by Lensa</title>
    </Head>
    <Script type='text/javascript' src='https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js' async />
    <Component {...pageProps} />
  </>
);

export default App;
